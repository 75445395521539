<template>
  <div>
    <div>

      <div>
        <br>

        <b-form
          ref="form"
          class="repeater-form"
          @submit.prevent="repeateAgain"
        >

          <!-- Row Loop -->
          <b-row
            v-for="(serie, index) in seriesP.series"
            :key="index"
            ref="row"
            class="pb-2"
          >

            <b-col
              md="2"
              lg="2"
            >
              <b-form-group
                label="Série"
                label-for="serie"
              >
                <b-form-input
                  id="serie"
                  v-model="serie.serie"
                  maxlength="5"
                />
              </b-form-group>
            </b-col>

            <b-col
              md="4"
              lg="4"
            >
              <b-form-group
                label="Descrição"
                label-for="descricao"
              >
                <b-form-input
                  id="descricao"
                  v-model="serie.descricao"
                  maxlength="50"
                />
              </b-form-group>
            </b-col>

            <b-col
              md="1"
              lg="1"
            >
              <b-form-group
                label="Numerador"
                label-for="h-numerador"
              >
                <validation-provider
                  #default="{ errors }"
                  name="numerador"
                  rules="required"
                >
                  <cleave
                    id="numerador"
                    v-model="serie.numerador"
                    class="form-control"
                    :raw="false"
                    :options="configNumerador.number"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="1"
              lg="1"
            >
              <b-form-group
                label="Ordem"
                label-for="h-ordem"
              >
                <validation-provider
                  #default="{ errors }"
                  name="ordem"
                  rules="required"
                >
                  <cleave
                    id="ordem"
                    v-model="serie.ordem"
                    class="form-control"
                    :raw="false"
                    :options="configOrdem.number"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="1"
              lg="1"
            >
              <b-form-group
                label="Inactiva"
                label-for="inactivo"
                label-class="mb-1"
              >
                <b-form-checkbox
                  :id="'inactivo' + index"
                  v-model="serie.inactivo"
                  value="1"
                />

              </b-form-group>
            </b-col>
            <b-col
              md="1"
              lg="1"
            >
              <b-form-group
                label="Por Defeito"
                label-for="porDefeito"
                label-class="mb-1"
              >
                <b-form-checkbox
                  :id="'porDefeito' + index"
                  v-model="serie.porDefeito"
                  value="1"
                />

              </b-form-group>
            </b-col>

            <!-- Remove Button -->
            <b-col
              md="2"
              lg="2"
              class="mb-500"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-2 mt-md-2"
                @click="removeItem(index)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-25"
                />
                <span>Apagar</span>
              </b-button>
            </b-col>

            <b-col cols="12">
              <hr>
            </b-col>
          </b-row>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="adicionarNovaSerie"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>Nova Série</span>
          </b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'

import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import Cleave from 'vue-cleave-component'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import pt from 'vee-validate/dist/locale/pt_PT'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    Cleave,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,

  },
  directives: {
    Ripple,
  },

  props: {
    seriesP: Object,
    documentosProcesso: Object,
  },

  data() {
    return {
      required,
      configNumerador: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'none',
          numeralIntegerScale: 6,
        },
      },
      configOrdem: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'none',
          numeralIntegerScale: 2,
        },
      },
      i: 0,
      documentoProcesso: {
        idTipoDoc: '',
        seriesProcesso: [],
      },
    }
  },
  setup() {
    const serieEmBranco = {
      serie: '',
      descricao: '',
      inactivo: 0,
      numerador: '',
      ordem: '',
      porDefeito: 0,
    }

    const series = ref({
      items: [JSON.parse(JSON.stringify(serieEmBranco))],
    })

    return {
      series,
      serieEmBranco,
    }
  },

  created() {
    this.seriesP.series = [JSON.parse(JSON.stringify(this.serieEmBranco))]
    localize('pt', pt)
  },
  methods: {
    teste() {

    },
    adicionarNovaSerie(index) {
      this.updateIndex(index)
      this.$refs.form.style.overflow = 'hidden'
      this.seriesP.series.push(JSON.parse(JSON.stringify(this.serieEmBranco)))
    },
    removeItem(index) {
      this.showMsgBoxTwo(index)
    },

    updateIndex(index) {
      this.i = index
    },

    showMsgBoxTwo(row) {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm('Pretende Remover ?', {
          title: 'Confirmação',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          this.boxTwo = value
          if (this.boxTwo === true) {
            this.deleted(row)
          }
        })
    },
    showToast(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Sucesso',
          icon,
          text: 'Registo gravado com sucesso !',
          variant,
        },

      })
    },
    deleted(row) {
      axios.delete(`/api/v1/seriesProcesso/${this.seriesP.series[row].id}`)
        .then(this.seriesP.series.splice(row, 1), this.showToast('success', 'CheckIcon'))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

@import '@core/scss/vue/libs/vue-good-table.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
